@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src:url('./fonts/poppins/Poppins-Regular.ttf') format('truetype'),
    url('./fonts/poppins/Poppins-SemiBold.ttf') format('truetype')
}
* {
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    font-size: 14px;
    margin: 0;
}
.collapse-container{
    display: flex;
    align-items: center;
}
.myclass{
    padding-bottom: 5px;
    padding-top:20px ;
    padding-left: 0px;
    background-color: #faf8f8;
}

/* Company Details */

.edit-image.company-image{
    position: relative;
    margin: 0 auto;
}

.company-image-container .company-icon-button{
    position: relative;
    /*top: -35px;*/
    /*margin-bottom: -30px;*/
    margin-left: 43.6%;

}

.company-image-container .company-icon-button .company-icon-holder{
    position: relative;
    background: rgba(51, 51, 51, 0.61);
    color: white;
    border-radius: 50%;
    top: -35px;
    margin-bottom: -15px;
    /*margin-left: 15%;*/
    /*width: 80%;*/
    /*height: 50px;*/
}
.company-image-container  .edit-image img, .edit-image {
    width: 110px;
    height: 110px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
    border: 1px solid gray;


}
.company-image-container .edit-image span{
    display: inline-block;
    position: relative;
    top: 30px;
    margin-bottom: -30px;
    /* margin-left: 18%; */
    margin-right: 10px;
    margin-left: 10px;
    font-size: 12px;
    text-align: center;
    font-weight: 700;
}

.row{
    margin-bottom: 1rem;
}
.general-referral-report{
    margin: 0 auto;
    max-width: 1000px;
    width: 90%;
}

/*Added these for the surgery module*/

.adjust-filter-by {
    /*margin-left: 20px;*/
}

.textsurgery-align {
    /*padding : 10px;*/
}

.flex-grow-zero {
    flex-grow: 0;
}

.zero-border-radius-txt {
    border-radius: 0;
    font-size: 12px;
}

.zero-border-radius-txt-months {
    border-radius: 0;
    font-size: 10px;
    padding: 2px;
}

.zero-border-radius {
    border-radius: 0;
}

.left-border-radius {
    border-top-left-radius: 0.7rem;
    border-bottom-left-radius: 0.7rem;
}

.right-border-radius-txt {
    border-radius: 0 0.7rem 0.7rem 0;
    font-size: 12px;
}

.right-border-radius-txt-days {
    border-radius: 0 0.7rem 0.7rem 0;
    font-size: 10px;
    padding : 2px;
}

.right-border-radius {
    border-radius: 0 0.7rem 0.7rem 0;
}

.surgery-filter {
    padding-top: 12px;
    /*text-align: center;*/
    padding-bottom: 10px;
}

.surgery-filter-components {
    display: flex;
}

.surgery-module-icon {
    width : 20px;
    height: 20px;
}

.icon-resize {
    width : 50px;
    height: 50px;
}

.surgery-icon-tabs {
    filter: opacity(0.45) drop-shadow(0 0 0 #027a5f);
}
.date-doctor-style {
    margin-bottom: 0;
    text-align: right;
}

.date-above {
    margin-top: 9px;
}
.doctor-below {
    margin-top: 3px;
}

.surgery-details {
    font-size: 13px;
    margin-top: 0.5rem;

}

.doctors-details {
    margin-top: 0;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    padding-top: 5px;
    padding-left:  20px;
    padding-right: 20px;
}

.surgery-components {
    margin-bottom: 0;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    padding: 10px 20px 0;
}

.flex-things {
    display:flex;
    flex-direction: row;
}

.surgery-print-button {
    margin-right: 20px;    
}

.surgery-print-titles {
    font-size: 14px;
    margin-bottom: 0.1rem;
    margin-top: 0.5rem;
    border-bottom: 3px solid #2b2a2a;
    font-weight: 600;
    padding: 0 0 5px;
}

.h7-titles {
    font-size: 14px;
    margin-bottom: 0.1rem;
    margin-top: 0.5rem;
    border-bottom: 2px solid #5e5e5e;
    font-weight: 400;
    padding: 0 0 5px;
}

.table-top-margin {
    margin-top: 14px;
}

.surgery-report-text {
    font-size: 13px;
    margin:5px;
}

.triage-surgery {
    /*margin-top: 0%;
    padding-top: 0%;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    padding-top: 5px; */
    padding-left:  40px;
    padding-right: 40px;
}

.surgery-report {
    margin:20px;
}

.surgery-patient-details table td {
    width: 50%;
}   

/*CSS for the surgery module stops here*/

.collapse-h6 {
    font-size: 16px !important;
}

th,
td {
    font-weight: 400 ;
}

th {
    font-size: 0.875rem ;
}

strong, b {
    font-weight: 600;
}

td {
    font-size: 0.875rem;
}

.text-pending {
    color: #ff660f;
}

button, input, optgroup, select, textarea, option {
    font-size: 0.95em;
}

.report_p {
    margin: 4px 0;
}

.dotted-span {
    display: inline-block;
    border-bottom: 2px dotted #807d7d;
    margin-left: 5px;
}

.underline {
    text-decoration: underline;
}

.table-grey {
    background-color: #e6e6e6;
}

table.vendor-address-table p, table.vendor-address-table h4, table.vendor-address-table h6 {
    margin: 0;
}

table.vendor-address-table tbody td {
    padding: 0;
}

.vendor-table thead {
    background-color: #1290a4;
    color: #fff;
    text-transform: uppercase;
}

.item-table > .table-bordered {
    border-bottom: 2px solid #a9aaaa;
}

.vendor-table th {
    border: 0;
    text-align: center;
    font-weight: normal;
}

.vendor-row td {
    text-align: center;
}

.vendor-table thead th {
    border-bottom: 0;
}

.vendor-table thead th:first-child {
    border-left: 1px solid #a9aaaa;
}

.vendor-table thead th:last-child {
    border-right: 1px solid #a9aaaa;
}

.vendor-table td {
    padding: .7rem;
}

.purchase-comment {
    text-decoration: underline;
}

.switch-admission-prescription {
    display: flex;
    min-height: 30px;
    margin-bottom: 5px;
    
}

.switch-register-patient {
    display: flex;
    justify-content: center;
    height: 90%;
    align-items: center;
}

.switch-add-referral {
    display: flex;
    justify-content: center;
    height: 90%;
    align-items: center;
}


.label-admission-prescription {
    margin-right: 15px;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
    padding: 10px 0px;
}


.form__control.drug_name.out-stock.out-stock::after {
    content: "Out of Stock";
    position: absolute;
    color: #ffba10;
    border-color: black;
    border-style: dotted;
}

.radio-btn .form-check-input {
    margin-top: 0.2rem;
}

/*h6{*/
/*    font-size:15px*/
/*}*/

a {
    text-decoration: none;
    cursor: pointer;
}

address {
    margin-bottom: 5px;
}

.btn {
    font-size: 14px !important;
}

.sms-logo {
    width: 40px;
}

.app {
    min-height: 120vh;
}

.recharts-legend-item-text {
    color: #000000de !important;
}

.patient-profile-summary .MuiExpansionPanelSummary-content {
    margin: 0 !important;
}

.patient-profile-summary .MuiExpansionPanelSummary-content,
.patient-profile-summary-details.MuiExpansionPanelDetails-root {
    display: block !important;
}

.patient-profile-row.row {
    margin: 0;
}

.styled-reset-card {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.styled-reset {
    width: 400px;
    margin: 10% auto;
}

.styled-reset-card .styled-reset-content h4 {
    color: #389081;
}

/*#container {*/
/*    -webkit-box-sizing: border-box;*/
/*    -moz-box-sizing: border-box;*/
/*    box-sizing: border-box;*/
/*    padding: 10px;*/
/*    width: 100%;*/
/*    height: 400px;*/
/*    background-color: #fff;*/
/*}*/

.icons {
    font-size: 24px;
    color: #f7f7f7;
}

.dropdown-front {
    z-index: 999;
}

.doc-icons {
    width: 100px;
    height: 100px;
}

.mui-menu-item {
    font-size: 14px !important;
    color: #000000de;
}

.MuiCard-root.general-card {
    overflow: initial !important;
}

.collect-sample {
    max-width: 900px;
    margin: auto;
}

.lab-patient-details {
    border-bottom: 3px double #343434;
}

.patient-id-search,
.patient-information,
.patient-triage,
.test-code-search {
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    padding: 10px 20px;
}
.patient-personal-details{
    border-bottom: 3px solid #2b2a2a;
    font-weight: 600;
    padding: 0 0 5px;
    margin-bottom: 15px;
}
.patient-personal-details.discharge{
    margin-bottom: 30px;
}

.patient-surgery-details {
    border-bottom: 1px solid black;
    font-weight: 200;
    padding: 0 0 5px;
    margin-bottom: 15px;
}

.dark-patient-id-search,
.dark-patient-information,
.dark-patient-triage,
.dark-test-code-search {
    border: 1px solid rgba(255, 255, 255, 0.12);
    /*border-radius: 6px;*/
}

.test-code-search {
    width: 40%;
}

.dark-table-bordered {
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.dark-table-bordered td,
.dark-table-bordered th {
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.dark-table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(255, 255, 255, 0.12);
}

.dark-table tbody + tbody {
    border-top: 2px solid rgba(255, 255, 255, 0.12);
}

.table-surgery-patient table {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0);
}

.table-surgery-patient thead {
    /*border-top: 1px solid rgba(0, 0, 0);*/
    border-bottom: 1px solid rgba(0, 0, 0);
}

.table-surgery-patient td {
    padding: 6px;
    width: 16%;
    text-align: center;
}


/*.visit-ul {*/
/*    width: 180px;*/
/*    height: 300px;*/
/*}*/

/*.lab-ul {*/
/*    width: 300px;*/
/*    height: 300px;*/
/*}*/

.prescription-ul,
.visit-ul,
.lab-ul {
    width: auto;
    /* height: auto; */
    font-size: 14px;
}

.diagnosis-ul {
    height: 280px !important;
    /*white-space: nowrap;*/
    font-size: 13px;
}

@media (max-width: 1300px) {
    .diagnosis-ul {
        width: 350px;
        overflow-x: auto;
    }

    .searchable .col-sm-4 {
        flex: 0 0 33%;
        max-width: 33%;
    }

    .searchable .col-sm-2 {
        flex: 0 0 16%;
        max-width: 16%;
    }
}

@media (min-width: 1350px) and (max-width: 1450px) {
    .diagnosis-ul {
        width: 450px;
        overflow-x: auto;
    }
}

@media (min-width: 1451px) and (max-width: 1800px) {
    .diagnosis-ul {
        width: 500px;
        overflow-x: auto;
    }
}

@media (min-width: 2000px) {
    .diagnosis-ul {
        width: auto;
    }

    .searchable .col-sm-4 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (min-width: 2000px) {
    .diagnosis-ul {
        width: auto;
    }

    .searchable .col-sm-4 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .searchable .col-sm-2 {
        flex: 0 0 10%;
        max-width: 10%;
    }
}

@media (min-width: 3000px) {
    .searchable .col-sm-4 {
        flex: 0 0 13%;
        max-width: 13%;
    }

    .searchable .col-sm-2 {
        flex: 0 0 6%;
        max-width: 6%;
    }
}

.drug-ul {
    /*margin-top: -107px;*/
    /*margin-left: 15px;*/

    /*border: 1px solid rgba(0, 0, 0, .125);*/
    padding: 0;

    overflow-y: scroll;
    /*background: rgb(255, 255, 255);*/
    color: rgb(51, 51, 51);
    position: absolute;
    /*top: 200;*/
    /*left: 50;*/
    z-index: 100;
}

.drug-li {
    cursor: pointer;
    padding: 4px;
}

.add-unit-form {
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
}

.medicine-unit-list {
    max-width: 600px;
    width: 90%;
    margin: 0 auto;
}

.test-code-search .search-btn {
    margin-top: 12px;
}

.patient-id-search .form-group,
.test-code-search .form-group {
    margin-bottom: 0;
}

.patient-information p,
.lab-patient-details p,
.lab-report p,.exam-notes p,
.general-lab-report p {
    margin: 0;
    
}

.toggle-admission-prescription{
    z-index: 0;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #faf8f8;
}

.custom-switch .custom-control-label::after {
    background-color: #faf8f8;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #faf8f8;
    border-color: #3e9e8e;
    background-color: #3e9e8e;
}


.custom-control-label::before {
    background-color: #404346;
}

.general-lab-report,
.radiology-report {
   /* max-width: 1000px;*/
   max-width: 205mm;
    margin: auto;
}

#general-lab-report p{
   /*font-size: 20px;*/
    font-size: 13px;
}

.lab-report {
   /*margin-left: 50px;*/
   /*margin-right: 50px;*/
   padding-left: 20px;
   padding-right: 20px;
   
}

.exam-notes {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 1px;
    padding-right: 40px;
}

.lab-report p {
    /*font-size: 22px;*/
    font-size: 13px;
}

#lab-result-pdf p {
   font-size: 13px;
}

.month-title {
    color: #027a5f;
    font-weight: 400;
}

.day-header {
    color: #027a5f;
    font-weight: 400;
}

.day-header:nth-child(1) {
    color: red;
}

.day:nth-child(1) {
    color: red;
}

.calendar .months-container .month-container {
    height: 240px;
    padding-top: 10px;
    border-style: inset;
    border-color: #011;
    border-width: 0.05rem;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    padding-bottom: 50px;
}

.flex-grow-zero {
    flex-grow: 0;
    min-width: fit-content;
}

.form-responsive {
    display: flex;
    margin-top: 35px;
    flex-wrap: wrap;
}

@media (max-width: 800px) {
    .form-responsive {
      flex-direction: column;
    }
  }


@media print {
    .p-general-report table td span, table th span, .p-general-report table td span p, .p-general-report span.MuiTypography-root{
      font-size:14pt !important;
    }

  }



.p-general-report {
    width: 200mm;
}

.patient-lab-report,
.patient-general-report {
    margin: 20px 0;
}

.heama-tables {
    border-bottom: 3px solid #3e3e3e;
    margin-bottom: 20px;
}

.patient-general-report,
.patient-lab-report {
    border-bottom: 3px solid #3e3e3e;
}

.dark-patient-general-report,
.dark-patient-lab-report,
.dark-heama-tables {
    border-bottom: 3px solid #fff;
}

.parameter-row {
    background-color: #c9c9c9;
    border: 1px solid #606060; 
}

.table-bordered.assignment-table td {
    border: 1px solid #000;
}


.table-bordered.billing-invoice-table thead td, .table-bordered.billing-invoice-table thead th {
    border-bottom-width: 0;
}

.table-bordered.billing-invoice-table.procedure-table td, .table-bordered.billing-invoice-table.procedure-table th {
    border-left: 0;
    border-right: 0;
}

.table-bordered.billing-invoice-table.procedure-table thead td, .table-bordered.billing-invoice-table.procedure-table thead th {
    background: #dddddd;
    color: #000000;
}


table.fill-prescription-table.smallFontSize tr td { font-size: 12px; padding: 0px; }
.table-bordered.fill-prescription-table thead td, .table-bordered.fill-prescription-table thead th {
    font-weight: 700;
} 

.table-bordered.fill-prescription-table td, .table-bordered.fill-prescription-table th {
    border: 1px solid #bdbdbd;
    text-align: center;
    padding: 2px;
    font-size: 14px;
    vertical-align: middle;
}

.table-bordered.fill-prescription-table thead td, .table-bordered.fill-prescription-table thead th {
    background: #dddddd;
    color: #000000;
}

.table-bordered.fill-prescription-table td table {
    border: 1px solid #bdbdbd;
    border-left: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
    width: -webkit-fill-available;
    padding: 2px;
}

table.triage-table.smallFontSize tr td { font-size: 12px; padding: 0px; }

.table-bordered.triage-table td, .table-bordered.triage-table th {
    border: 1px solid #c2c3c5;
    text-align: center;
    padding: 5px;
    font-size: 12px;
}

.table-bordered.triage-table thead td, .table-bordered.triage-table thead th {
    background: #eaf2ff;
    color: #000000;
}


.diagnosis-notes {
    border: 1px solid #ced4da;
    padding: 8px;
    border-radius: 0.7rem;
    background-color: #fff;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.profile-table.table-bordered td, .profile-table.table-bordered th {
    border: 1px solid #606060;
}

table.no-body-border tbody td {
    border-right: 1px solid #606060;
    border-left: 1px solid #606060;
}

table.no-body-border thead td, table.no-body-border tfoot td {
    border: 1px solid #606060;
}

table.trial-table tfoot td:last-child, table.trial-table tfoot td:nth-child(2), table.balance-sheet-data > tfoot td:last-child,
table.invoice-table > tfoot td:last-child {
    border-top: 1px solid #606060;
    border-bottom: 4px double #606060;
}

table.balance-sheet-data > tfoot td {
    border-top: 1px solid #606060;
}

table.general-ledger-table tbody tr:nth-child(even) td {
    border-bottom: 1px solid #e4e4e4;
}

.general-ledger-header {
    padding: 10px;
    border-bottom:1px solid #dcdcdc;
    /*background-color: #fafafa;*/
    margin-bottom: 10px;
}
.general-ledger-header.inpatient-billing-header{
    padding-bottom: 0;
    padding-left: 0;
    margin-bottom: 0;
}

.billing-span{
    border-right: 1px solid #e0e0e0;
    padding: 8px 15px 8px 10px;
    color: #434449;
    border-radius: 2px;
}
.billing-span:last-child{
    border-right: 0;
}
.billing-span:hover,.billing-span:focus{
    background: #f1f1f4;
    color: #3f51b5;
    text-decoration: none;

}

.active-billing-span{
    border-left: 5px solid #3f51b5;
    background: #f1f1f4;
    color: #3f51b5;
}
.report-search-bar{
    margin-bottom: -20px;
}

.report-search-bar{
    margin-bottom: -20px;
}

.new-entry-card {
    max-width: 1100px;
    width: 90%;
    margin: 0 auto;
}

.table-sm.balance-sheet-table td, .table-sm.balance-sheet-table th {
    padding: .1rem;
}

.table-sm.balance-sheet-details td:first-child, .table-sm.balance-sheet-details th:first-child {
    padding-left: 1rem;
}

.table-sm.balance-sheet-details tfoot td, .table-sm.balance-sheet-details tfoot th {
    border-top: 1px solid #606060;
}

.journals .sub-header {
    margin-bottom: 0;
}

.export-dropdown {
    width: 30%;
    margin-top: 5px;
}

.export-dropdown .btn-success, .export-dropdown .btn-success:hover, .export-dropdown .btn-success.dropdown-toggle:focus {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    color: #0b0b0b;

}

.export-dropdown .btn-success.dropdown-toggle:focus {
    box-shadow: none;
}


table.invoice-table thead tr:nth-child(2) td, table.trial-table thead tr:nth-child(2) td {
    border-top: 1px solid #606060;
    border-bottom: 1px solid #606060;
}

table.invoice-table tfoot td {
    font-weight: 600;
}

.top-bar {
    border-bottom: 1px solid #e4e4e4;
    padding: 18px 10px 18px;
}

.top-bar.all-journals {
    background-color: #f8f7f7;
}

.sub-journal-bar {
    padding: 10px;
    border-bottom: 2px dashed #a3b7e2;
    background-color: #f8fcff;
}

.debit-table {
    margin: 0;
}

.accounting-period {
    max-width: 900px;
    width: 90%;
    margin: 5rem auto 10rem;
}

.add-transaction-types {
    max-width: 950px;
    width: 90%;
    margin: 1rem auto 10rem;
}

.dark-parameter-row {
    background-color: #505050;
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.result-border {
    border-bottom: 1px solid #606060;
}

.lab-report-summary .sub-header {
    margin-bottom: 0;
}

.lab-report-summary .tab-content > .active, .lab-report-summary .nav-item {
    color: #000;
}

.lab-report-summary .nav-link {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.lab-report-summary .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-top: 3px solid #157e3f;
}

.emp-border {
    border-bottom: 1px solid #d1d1d1;
}

.parameter-row td {
    padding: 0.1rem;
    color: black;
}

.haem-val-table td {
    padding: 0.1rem;
}

.general-txt {
    font-weight: bold;
    margin-top: 10px !important;
}

.title-background {
    background-color: rgb(242, 242, 242);
    padding: 3px;
}

.dark-title-background {
    background-color: rgb(78, 78, 78);
}

.title-background h6 {
    margin: 0;
    font-weight: 600;
}

.hosp-address {
    font-size: 14px;
    margin-bottom: 20px;
}


.address-p {
    margin-top: 1.7rem !important;
}

.icon-button {
    background-color: rgb(242, 242, 242);
    border-radius: 47%;
    padding: 6px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.54);
}


.patient-prescription-report {
    max-width: 900px;
    margin: auto;
}

.prescription-report {
    width: 595px;
    margin: auto;
}

.prescription-table td,
.prescription-table th {
    padding: 0;
}

.prescription-table p {
    margin: 0;
}

/*.footer-table {*/
/*    margin-top: 30px;*/
/*}*/

@media screen {
    table.footer-table {
        display: none;
    }
    
    .mpeke-footer {
        display: none;
    }
}

@media print {
    .mpeke-footer {
        display: table-footer-group;
        width:100%;
        position: absolute;
        bottom: 0;
    }

    .mpeke-footer-text {
        text-align: left;
    }

}

.text-small {
    font-size: 12px !important;
}

.lab-report-heading h5 {
    text-decoration: underline;
}

.lab-report .form-group {
    margin-bottom: 0.5rem;
}

.lab-report-details {
    border: 2px dotted #909090;
}

.param-table {
    margin-bottom: 0;
}

.search-btn.btn {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.main-header {
    position: relative;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.smsLogo {
    cursor: pointer;
    text-decoration: none;
    font-size: 1.1em;
    margin-left: 5%;
    color: #702acf;
    text-shadow: 1px 4px 3px rgba(174, 174, 174, 0.3);
    font-weight: 600;
}

.smsLogo img {
    /*width: 100px;*/
    margin-left: 10px;
}

.smsLogo-root {
    cursor: pointer;
    text-decoration: none;
    font-size: 1.2em;
    color: #32a852;
    text-shadow: 1px 4px 3px rgba(174, 174, 174, 0.3);
    font-weight: 600;
}

.smsLogo:hover {
    color: #32a552;
}

.kpcLogo {
    font-size: 1.3em;
    font-weight: bold;
}

.sms-user-icon {
    width: 35px;
    border-radius: 50%;
    cursor: pointer;
}

.sms-user-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.split-text {
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    white-space: nowrap;
}

.header-info {
    padding: 31px 0;
}

.header-info .main-logo div {
    display: block;
    line-height: inherit;
    height: inherit;
    padding: 0;
}

.date-picker-borderless {
    border-bottom: 0.5px solid #e0e1e5;
    border-top: none;
    border-right: 2px solid #d9dade;
    border-left: 2px solid #d9dade;
    border-radius: 10%;
    color: #777777;
    background-color: #fefefe;
}

.date-picker {
    overflow: hidden;
    white-space: nowrap;
    margin-top: 3px;
    margin-left: -15px;
    border-top: 1px solid #cfd0d4;
    border-bottom: 1px solid #cfd0d4;
    border-left: 1px solid #cfd0d4;
    border-radius: 5%;
    padding-inline-start: 15px;
    padding-right: -5px;
    color: #6a6a6a;
    padding-top: 5px;
    padding-bottom: 4px;
}

.date-picker-labels {
    overflow: hidden;
    white-space: nowrap;
    margin-left: 10px;
}

/*edit icon {*/
/*    background: blue;*/
/*}*/

/*.px-50{*/
/*  padding:0 60px 60px 60px;*/
/*}*/

.pr-50 {
    padding-right: 50px !important;
}

.header-contact-info ul li {
    /* display: inline-block; */
    float: left;
    margin-right: 25px;
    padding-left: 50px;
    position: relative;
}

.header-contact-info ul li:last-child {
    margin-right: 0;
    border-right: none;
    padding-right: 0;
}

.header-contact-info ul li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border: 1px solid #d3d4d8;
    border-radius: 50%;
    text-align: center;
    line-height: 48px;
    color: #3fa9bc;
    font-size: 27px;
}

.header-contact-info ul li .icon-holder span.top-envelop {
    font-size: 40px;
}

.header-contact-info ul li:hover .icon-holder span {
    color: #2b2c36;
}

.header-contact-info ul li .text-holder {
    padding-left: 10px;
}

.header-contact-info ul li .text-holder h6 {
    color: #151515;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 5px;
}

.header-contact-info ul li .text-holder p {
    color: #9f9f9f;
    font-size: 17px;
    font-weight: 400;
    margin: 0;
    line-height: 16px;
}

.doc-dashboard-item.MuiListItem-root + .MuiListItem-root {
    border-top-width: 0;
}

.doc-dashboard-item span {
    font-size: 14px !important;
}

.lab-dashboard-item.MuiListItem-root + .MuiListItem-root {
    border-left-width: 0;
}

.lab-dashboard-item span {
    font-size: 15px !important;
}

.theme-btn {
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.btn-style-two {
    position: relative;
    padding: 11px 40px;
    border-radius: 15px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    background: #3fa9bc;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid #3fa9bc;
    font-family: "Montserrat", sans-serif;
}

.btn-style-two:hover {
    color: #ffffff;
    background: #5c5959;
    border-color: #6d6a6a;
}

.blue-bg {
    background-color: #3a9ed8 !important;
}

.main-header .header-upper {
    position: relative;
    z-index: 5;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.nav-item {
    color: white;
}

.main-image {
    min-height: 300px;
}

.main-image .container-fluid {
    padding: 0;
}

.main-image .image-wrapper .image img {
    min-height: 300px;
    width: 100%;
    max-width: none;
}

.main-image .caption {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 5;
    color: #3a9ed8;
}

.sp-two {
    padding: 50px 0 90px;
}

/* AppBar */

div.single-module {
    color: #cbe5ff;
    display: block;
    padding: 50px 25px;
    background: #3a9ed8;
    text-align: center;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.single-module-img img {
    width: 80px;
    height: 80px;
}

.single-module-text h4 {
    font-size: 22px;
    text-transform: capitalize;
    margin: 20px 0;
}

div.single-module:hover {
    background: white;
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    color: black;
}

/*
td,
th {
  text-transform: capitalize;
} */

/* .app { */
/* background: #f8f8ff; */
/* background-size: cover; */
/* height: 100%; */
/* } */
.FormField__Button {
    /* background-color: #3a9ed8; */
    color: white;
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 10px 80px;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 10px;
}

.FormField__btn {
    /* background-color: #52c4b9; */
    color: white;
    border: none;
    outline: none;
    /* border-radius: 25px; */
    /* padding: 10px 60px; */
    font-size: 1em;
    font-weight: 500;
}

.FormField__Cancel {
    background-color: rgb(240, 247, 247);
    color: black;
    border: none;
    outline: none;
    border-radius: 25px;
    padding: 10px 60px;
    font-size: 1em;
    font-weight: 500;
}

.FormField__Link {
    color: rgb(14, 15, 15);
    text-decoration: none;
    display: inline-block;
    border-bottom: 1.5px solid #225e62;
    padding-bottom: 5px;
}

.FormField__CheckboxLabel {
    color: rgb(7, 7, 7);
    font-size: 0.9em;
}

.FormField__Checkbox {
    position: relative;
    top: 1.5px;
}

.FormField__TermsLink {
    color: white;
    border-bottom: 1px solid #199087;
    text-decoration: none;
    display: inline-block;
    padding-bottom: 2px;
    margin-left: 5px;
}

.active {
    text-decoration: none;
    color: #3a9ed8;
    /* text-shadow: 2px 2px 4px #676767; */
    font-weight: 500;
}

.navlink .navTab {
    display: block;
    font-size: 14px;
    /* font-weight: 600; */
    text-shadow: 1px 6px 3px rgba(174, 174, 174, 0.3);
    text-decoration: none;
    /* text-shadow: 3px 3px 3px #80c8cf; */
    padding-top: 5px;
}

.navlink .lightTab {
    color: #011;
}

.navlink .darkTab {
    color: #fff;
}

.navlink .lightTab:hover {
    color: #2f7a6e;
    font-weight: bolder;
    text-shadow: 1px 6px 3px rgba(174, 174, 174, 0.36);
    text-decoration: none;
}

.navlink .darkTab:hover {
    color: #fff;
    font-weight: bolder;
    text-shadow: 1px 6px 3px rgba(174, 174, 174, 0.36);
    text-decoration: none;
}

.navlink .navName {
    display: block;
    color: #919191;
    font-size: 14px;
    /* font-weight: 600; */
    padding-top: 15px;
    text-transform: uppercase;
    text-shadow: 1px 6px 3px rgba(174, 174, 174, 0.22);
    text-decoration: none;
    /* text-shadow: 3px 3px 3px #80c8cf; */
}

.navlink .navName:hover {
    color: rgba(60, 156, 141, 0.82);
    font-weight: bolder;
    text-shadow: 1px 6px 3px rgba(174, 174, 174, 0.22);
    text-decoration: none;
}

.navlink1 {
    display: block;
    color: rgb(77, 75, 75);
    font-size: 14px;
    font-weight: inherit;
    text-decoration: none;
    /* text-shadow: 3px 3px 3px #80c8cf; */
}

.navigation-links .navlink:hover {
    text-decoration: none;
    color: #3a9ed8;
    font-weight: 500;
}

.navlink1:hover {
    text-decoration: none;
    color: rgb(99, 96, 96);
    font-weight: 500;
}

.help-block {
    color: rgb(199, 40, 40);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*Sidebar*/
#sidebar-wrapper {
    z-index: 1;
    position: absolute;
    width: 250px;
    height: 100%;
    overflow-y: hidden;
    background: #f7f7f7;
    opacity: 0.9;
}

.navbar#content-nav {
    height: 65px;
}

#menu-toggle,
h1,
p {
    margin: 20px;
}

/*Sidebar top navbar*/
.navbar.menu-navbar {
    text-indent: 14px;
    background: #e9e9e9;
}

.processingLink {
    background-color: #fff;
}

.navbar h5 {
    margin-top: 5px;
    color: #444;
}

.navbar span {
    color: #1cbc88;
    margin-left: 10px;
}

.navbar:hover {
    text-decoration: none;
}

/*Main content*/

#page-content-wrapper {
    line-height: 30px;
    position: absolute;
    margin-left: 250px;
    margin-right: 0;
}

.sidebar-nav {
    padding: 0;
    list-style: none;
    transition: left 0.25s ease-in;
    overflow: auto;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #444;
}

.sidebar-nav li a:hover {
    background: rgba(3, 125, 88, 0.52);
    color: #fff;
}

.sidebar-nav ul {
    list-style: none;
}

.hidden {
    display: none;
}

.show {
    display: block;
}

/* .profile-area{
  margin-top: 1%;
background-color: #f7f7f7
}
.single-member{
  box-shadow: rgba(58, 78,95,0.2) 0 10px 16px, rgba(58, 78,95,0.05) 0 -5px 15px;
  width: 500px;
} */
.single-member .team-heading {
    /*background: url("../images/profile_img.png");*/
    background-size: cover;
    height: 200px;
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
}

.single-member .member-img {
    position: relative;
    top: -60px;
    margin-bottom: -60px;
    margin-left: 3%;
}

.single-member .member-img img {
    width: 100px;
    height: 100px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.5);
}

.member-data {
    padding: 5%;
    min-width: 275px;
    /*background-color: white;*/
    box-shadow: rgba(58, 78, 95, 0.2) 0 10px 16px;
}

.member-info {
    margin-bottom: 10%;

    /* background-color: white; */
}

.treatment-area {
    margin-top: 1%;
}

.header-image {
    /*background: url("./images/doc.jpg");*/
    background-size: cover;
    height: 450px;
}

.treatment-info {
    position: relative;
    top: -80px;
    margin-bottom: -80px;
    margin-left: 5%;
}

/* .single-patient { */
/* box-shadow: rgba(58, 78, 95, 0.2) 0 10px 16px,
    rgba(58, 78, 95, 0.05) 0 -5px 15px; */
/* width: 500px; */
/* } */

.single-patient .patient-heading {
    /*background: url("../images/machine.jpg");*/
    background-size: cover;
    height: 300px;
}

.single-patient .member-img {
    position: relative;
    top: -60px;
    margin-bottom: -60px;
    margin-left: 5%;
}

.single-patient .member-img img {
    width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.5);
}

/*----------
Users Component
-----------*/

.page-wrapper {
    left: 0;
    /* margin-left: 200px; */
    padding-top: 50px;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.page-wrapper > .content {
    padding: 30px;
}

.page-title {
    color: #565656;
    font-size: 21px;
    font-weight: normal;
    margin-bottom: 20px;
}

.profile-widget {
    /*background-color: #fff;*/
    border-radius: 4px;
    /*box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);*/
    margin-bottom: 30px;
    padding: 10px 10px 15px;
    text-align: center;
    /*position: relative;*/
    /*overflow: hidden;*/
}

.profile-widget .user-name > a {
    color: #333;
}

.user-img {
    cursor: pointer;
    height: 80px;
    margin: 0 auto 15px;
    position: relative;
    width: 80px;
}

.user-img .avatar {
    font-size: 24px;
    height: 80px;
    line-height: 80px;
    margin: 0;
    width: 80px;
    border: 1px solid gray;
    /*rgba(255, 255, 255, 0.5);*/
    border-radius: 50%;
}

.patient-img {
    cursor: pointer;
    height: 80px;
    margin: 0 auto 10px auto;
    position: relative;
    width: 80px;
}

.patient-img .avatar {
    /*font-size: 24px;*/
    height: 80px;
    margin: 0;
    width: 80px;
    border: 1px solid gray;
    /*rgba(255, 255, 255, 0.5);*/
    border-radius: 50%;
}

.profile-img {
    cursor: pointer;
    height: 60px;
    margin: 5px 85px;
    /*position: relative;*/
    margin-left: auto;
    width: 60px;
}

.profile-img .avatar {
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    border: 1px solid #a6a6a6;
    border-radius: 50%;
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.user-prof {
    color: #777;
    font-size: 12px;
    margin-bottom: 10px;
}

.user-country {
    color: #777;
    font-size: 14px;
}

.profile-action {
    position: absolute;
    right: 5px;
    text-align: right;
    top: 10px;
}

.profile-img-wrapper {
    height: 100px;
    position: relative;
    width: 100px;
    background: #fff;
    border: 1px solid #777;
    /* border-radius: 40%; */
    overflow: hidden;
}

.profile-img-wrapper img {
    width: 100px;
    height: 100px;
    /* border: #777; */
}

.fileupload.btn {
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(33, 33, 33, 0.5);
    border-radius: 0;
    padding: 3px 10px;
    border: none;
}

.fileupload input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: -3px;
    top: -3px;
    padding: 5px;
}

.btn-text {
    color: #fff;
}

.optional {
    color: #777;
    font-size: 12px;
}

.main {
    /* background: url("../images/smartmedbackground.svg"); */
    background: rgb(235, 233, 233);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 100vh;
      width: 100%; */
}

.mdb-btn {
    border-radius: 20%;
}

.feature-row {
    padding-left: 6%;
    padding-right: 6%;
    /*width:100%;*/
}

.feature-col {
    padding-left: 3%;
    padding-right: 3%;
}

.sms-section {
    height: 100vh;
    padding-top: 35px;
    /*overflow: initial;*/
    display: flex;
    flex-direction: column;
}

.light-sms-section {
    background: #fafafa;
}

.dark-sms-section {
    background: #111111;
    min-height: 100vh;
    padding-top: 35px;
    overflow: auto;
    /*padding-bottom:30px;*/
}

.sms-section::-webkit-scrollbar {
    width: 3px;
    height: 4px;
}

.sms-section::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eeeeee;
    border-radius: 10px;
}

.sms-section::-webkit-scrollbar-thumb {
    background-color: #e6e6e6;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}


.sms-bgd {
    background-image: linear-gradient(
            to bottom right,
            rgba(56, 144, 129, 0),
            #fff,
            #fff,
            #fff,
            #fff,
            #fff,
            rgba(95, 255, 232, 0),
            rgba(69, 178, 159, 0.05),
            rgba(56, 144, 129, 0.25)
    );
}

.sms-background {
    /*background-image: url("../images/healer_tree.png");*/
    background-repeat: no-repeat;
    background-position: right;
    background-attachment: fixed;
    opacity: 0.3;
    position: static;
}

.card-box {
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
}

.card-item {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.card-login {
    box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.1);
}


.doctor-card {
    background-color: rgba(1, 149, 152, 0.99);
    color: white;
}

.profile-card > .main-icon {
    border-radius: 0.55rem;
    float: left;
    width: 50px;
    height: 10px;
}


.profile-card > .edit-icon .edit-patient > span > .pen-icon {
    /*background-color: whitesmoke;*/
    font-size: 16px;
}

.profile-card > .content {
    float: left;
    width: 70%;
    margin: 0;
}

.profile-card .profile-image img {
    width: 120px;
    height: 130px;
    max-width: 120px;
    max-height: 130px;
    border-radius: 10%;
    border: 2px solid rgba(200, 200, 200, 0.49);
    margin-left: 95px;
    margin-top: 5px;
    margin-bottom: 15px;
    /*align-items: center;*/
}

.doc-card {
    background-color: #037a75;
    color: white;
}

.patient-info {
    /*color: #09879a;*/
    font-weight: 500;
    margin-bottom: 0;
    text-decoration: underline;
    font-size: 15px;
}

.heading {
    /*color: black;*/
    font-weight: bold;
}

.med-icons {
    font-size: 25px;
}

.invoice-icon {
    font-size: 30px;
}

.invoice-view-edit-icon {
    font-size: 20px;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    border: 1px solid #09879a;
    border-radius: 50%;
    text-align: center;
    /*line-height: 48px;*/
    color: #09879a;
    /*font-size: 27px;*/
    display: inline-block;
}

.invoice-view-delete-icon {
    font-size: 20px;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    border: 1px solid #b04963;
    border-radius: 50%;
    text-align: center;
    /*line-height: 48px;*/
    color: #b04963;
    /*font-size: 27px;*/
    display: inline-block;
}

.invoice-view-eye-icon {
    font-size: 20px;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    border: 1px solid #bfbfbf;
    border-radius: 50%;
    text-align: center;
    /*line-height: 48px;*/
    color: #bfbfbf;
    /*font-size: 27px;*/
    display: inline-block;
}

.invoice-eye-icon {
    color: #777777;
}

.invoice-eye-icon:hover {
    color: whitesmoke;
}

.invoice-view-eye-icon:hover {
    background: #e2e2e2;
    color: white;
}

.invoice-view-edit-icon:hover {
    background: #2ca4ad;
    color: white;
}

.invoice-view-delete-icon:hover {
    background: #b04963;
    color: white;
}

.invoice-add-icon {
    font-size: 20px;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    /*border: 1px solid #d3d4d8;*/
    /*border-radius: 50%;*/
    text-align: center;
    /*line-height: 48px;*/
    color: #09879a;
    /*font-size: 27px;*/
    display: inline-block;
}

.invoice-view-icon span {
    margin-top: 20px;
}

.info-icon {
    color: dodgerblue;
}

.prescription-icon {
    color: orange;
}

.lab-icon {
    color: gray;
}

.bill-icon {
    color: #999979;
}

.miscroscope-icon {
    color: gainsboro;
}

.small-btn{
    padding-top: 3px;
    padding-bottom: 3px;
}

.sms-btn {
    background-color: #389081;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sms-btn:disabled{
    cursor: not-allowed;
    pointer-events: none;
}

.form-group.medicine-use-group {
    margin-bottom: 0.2rem;
}

.pie-btn {
    background-color: #17a2b8;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 0;
}

.pie-btn:hover,
.pie-btn:focus {
    border: 1px solid #17a2b8;
    background-color: #fff;
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
    color: #17a2b8;
    border-radius: 0;
}

.active-pie-btn {
    border: 1px solid #17a2b8;
    background-color: #fff;
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
    color: #17a2b8;
    border-radius: 0;
}

.pie-btn.l-btn {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.pie-btn.r-btn {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.sms-btn:hover {
    border: 1px solid #389081;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #389081;
}

.sms-chips {
    background: #389081;
    color: whitesmoke;
    /*overflow: hidden;*/
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
}

.sms-chips:hover {
    border: 1px solid #389081;
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #389081;
}

/*view receipt*/
.sms-info-btn{
    background-color: #1fa7b0;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.create-appointment-button {
    display: inline;
    margin-left: 90%;
}

.sms-info-active-btn {
    border: 1px solid #1fa7b0;
    background-color: #fff;
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
    color: #1fa7b0;
}

.sms-info-btn:hover, .active-sms-info-btn {
    border: 1px solid #1fa7b0;
    background-color: #fff;
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
    color: #1fa7b0;
}

/*mark as done*/
.sms-btn-outline {
    background-color: #fff;
    color: #389081;
    border: 1px solid #389081;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sms-btn-outline:hover {
    background-color: #389081;
    color: whitesmoke;
}

.user-support .support-icon {
    font-size: 25px;
}

.fa {
    padding: 15px;
    font-size: 15px;
    /*width: 30px;*/
    text-align: center;
    text-decoration: none;
    margin: 5px 5px;
    border-radius: 50px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
    background: #3b5998;
    color: white;
}

.fa-twitter {
    background: #55acee;
    color: white;
}

.fa-whatsapp {
    background: #4fce5d;
    color: white;
}

/*
 * filter property: https://codepen.io/sosuke/pen/Pjoqqp
 */
.ante-natal-green {
    filter: brightness(0) saturate(100%) invert(35%) sepia(29%) saturate(1805%) hue-rotate(124deg) brightness(94%) contrast(98%);
}

/*green, blue and orange*/
/*view drugs*/
.sms-amber-btn {
    background-color: #ea8c05;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sms-hl-btn {
    background-color: rgba(200, 8, 51, 0.99);
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sms-hl-btn:hover {
    border: 1px solid rgba(200, 8, 51, 0.99);
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: rgba(200, 8, 51, 0.99);
}

.scan-card {
    background-color: #f9f9f9;
}


.input-container {
    position: relative;
}

.input-container > span {
    position: absolute;
    top: 8px;
    left: 105px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #c4495c;
    padding: 3px;
    border-radius: 30%;
    color: #fff;
    border-color: #ea6075;
}

.form-group.row {
    margin-left: 0;
}

.input-container > input {
    padding-right: 30px;
}

.sms-scan-btn {
    /*background-color: rgba(135, 138, 138, 0.99);*/
    /*color: white;*/
    background-color: #f0f0f0;
    color: black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    /*margin-left: auto;*/
    /*margin-right: auto;*/
}

.sms-scan-btn:hover {
    border: rgba(135, 138, 138, 0.99);
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: black;
}

.sms-amber-btn:hover {
    border: 1px solid #f0ad4e;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #cea12a;
}

.sms-btn-dismiss {
    background-color: #c4495c;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sms-btn-dismiss:hover {
    border: 1px solid #c4495c;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #c4495c;
}

.card-nav {
    z-index: 9999;
    position: relative;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.user-icon {
    font-size: 24px;
    border-radius: 50%;
    background-color: #389081;
    width: 60px;
    height: 60px;
    position: relative;
    display: block;
}

.user-icon span {
    color: white;
    position: absolute;
    margin-left: 20px;
    margin-top: 12px;
}

.user-icon span.person-icon {
    color: white;
    position: absolute;
    margin-left: 18px;
    margin-top: 12px;
}

span.responsive-tip {
    white-space: nowrap;
}

.card-title {
    color: #333;
    font-size: 16px;
    margin-bottom: 20px;
}

.drugIssue {
    display: block;
    color: #757575;
    text-decoration: none;
}

/* .main-cards{
  margin-left: 10%;
  margin-right: 20%

} */
.d-flex {
    height: 70vh;
}

.input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    border-bottom: 1px solid white;
    font-size: 1em;
    font-weight: 300;
    padding-bottom: 10px;
    margin-top: 2px;
}

.btn-success {
    width: 100%;
}

.responsive-div {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.responsive-div-right {
    padding-left: 30px;
    margin-bottom: 10px;
    margin-top: 50px;
    /*color: white;*/
}

/*Patient dashboard*/
.patient-dashboard-row {
    padding-top: 8%;
    padding-bottom: 8%;
    padding-left: 30px;
}

.mui-table-row {
    cursor: pointer;
}

.mui-table-row:hover {
    color: #c4495c;
}

.mui-table-cell span {
    /*color: #c4495c;*/
    font-size: 1.05em;
}

.responsive-patient-dashboard-text {
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
}

.patient-row-icon {
    /*margin-top: 10px;*/
    display: table-cell;
    vertical-align: middle;
}

.sms-text {
    color: #338375;
    margin-bottom: 30px;
    text-shadow: 1px 4px 3px rgba(174, 174, 174, 0.3);
    font-family: "Castellar", sans-serif;
    white-space: nowrap;
    font-size: 18px;
    display: none;
    /*font-weight: 500;*/
}

.login-text {
    color: #338375;
    margin-bottom: 20px;
}

.case-study-table {
    max-width: 1250px;
    width: 98%;
    margin: 0 auto;
}

/*@media (min-width: 1200px) {*/
/*  .container,*/
/*  .container-fluid {*/
/*    padding: 0;*/
/*  }*/

/*  .theme-btn {*/
/*    display: inline-block;*/
/*    transition: all 0.3s ease;*/
/*    -moz-transition: all 0.3s ease;*/
/*    -webkit-transition: all 0.3s ease;*/
/*    -ms-transition: all 0.3s ease;*/
/*    -o-transition: all 0.3s ease;*/
/*  }*/

/*}*/

.create-appointment-button {
    display: inline;
    margin-left: 70%;
}

.case-study-table {
    max-width: 1100px;
    width: 98%;
    margin: 0 auto;
}

.appointment-item {
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 0.1s;
    font-size: 14px;
}

.appointment-item:hover {
    cursor: pointer;
    background-color: rgba(212, 212, 212, 0.33);
    transform: scaleY(1);
    color: rgba(13, 110, 148, 0.83);
    font-weight: 600;
}

.nav-link-item {
    display: block;
    /*color: rgba(0, 0, 153, 1);*/
    color: rgba(223, 223, 223, 0.87);
    font-size: 16px;
    padding-right: 35px;
    font-weight: inherit;
    text-decoration: none;
    cursor: pointer;
    /*text-shadow: 3px 3px 3px #80c8cf;*/
}

.nav-link-item:hover {
    text-decoration: none;
    color: whitesmoke;
    transition: 0.5s ease;
    font-weight: 500;
}

.active {
    text-decoration: none;
    color: #f7f7f7;
    /*text-shadow: 2px 2px 4px #676767;*/
    /*font-weight: 500;*/
}

.side-links {
    /*display: block;*/
    /*color: rgba(0, 0, 153, 1);*/
    color: #d3d3d3;
    /*font-size: 16px;*/
    /*padding-right: 35px;*/
    /*font-weight: inherit;*/
    text-decoration: none;
    cursor: pointer;
    /*padding-top:10px;*/
    /*text-shadow: 3px 3px 3px #80c8cf;*/
}

.dashboard-item {
    padding-top: 20px;
}

.side-links:hover {
    text-decoration: none;
    color: #ffffff;
    /*transition: .5s ease;*/
    /*font-weight: 500;*/
    /*border-left:3px solid #fafcff;*/
}

.side-active {
    text-decoration: none;
    /*background-color: #389081;*/
    /*text-shadow: 1px 1px 4px #d3d3d3;*/
    /*font-weight: 500;*/
    color: #ffffff;
}

/*.side-active:hover {*/
/*  text-decoration: none;*/
/*  !*background-color: #389081;*!*/
/*  !*text-shadow: 2px 2px 4px #676767;*!*/
/*  font-weight: 500;*/
/*  !*color: white;*!*/
/*}*/

.main-footer {
    /*background-color: #307f8d;*/

    /*left: 0;*/
    /*bottom: 0;*/
    /*right: 0;*/
    /*color: white;*/
    /*top: 0;*/
    /*margin-top: -10px;*/
    margin-top: auto;
    border-top: 1px solid #cbcbcb;
}

.light-footer {
    background-color: white;
    color: #303030;
}

.dark-footer {
    color: white;
    border-top: 1px solid #cbcbcb;
}

/*Page Sections*/
.advanced-section {
    box-shadow: 5px 0 0 0 rgba(0, 0, 0, 0.1);
    padding-left: 5.5%;
    padding-top: 12px;
    margin-bottom: 40px;
    /*margin-top:-15px;*/
    /*background-color: #e0e0e0;*/
    /*background-image: linear-gradient(to right, #fff, #fff, #fff, #fff, #fff, #fafafa, #fefefe, #fefefe,*/
    /*#fff, #fff, #fff, #fff, #f9f9f9);*/
}

.section-basic {
    width: 96%;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    line-height: 0.1em;
    margin: 45px -5px 10px;
    margin-bottom: 20px;
}

.section-basic span {
    background: #fff;
    padding: 10px 15px;
    font-size: 1.3em;
    margin: 0 0 2em;
    color: #3b719b;
    margin-right: 50px;
}

.section-heading {
    width: 96%;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    line-height: 0.1em;
    margin: 20px 0 10px;
}

.section-heading span {
    background: #fff;
    padding: 10px 15px;
    font-size: 1.3em;
    margin: 0 0 2em;
    color: #2e5677;
    margin-right: 50px;
}

.status {
    color: white;
    border-radius: 15%;
}

.pending {
    background-color: #dd7e47;
    border: 3px solid #dd7e47;
}

.finished {
    background-color: #1aa36f;
    border: 1px solid #1aa36f;
}

.working {
    background-color: #ddaf11;
    border: 1px solid #ddaf11;
}

.complete-bill {
    background-image: linear-gradient(
            to right,
            rgba(239, 191, 17, 0.55),
            rgba(221, 175, 17, 0.64),
            #e6b611,
            #ddaf11
    );
    border: 1px solid rgba(221, 175, 17, 0.21);
}

.rescheduled {
    background-color: #0facdd;
    border: 1px solid #0facdd;
}

.lab-pending {
    background-color: #dd2f1a;
    border: 1px solid #dd2f1a;
}

.lab-status {
    color: white;
    border-radius: 50px;
    font-size: 14px;
}

.bill-status {
    color: white;
    border-radius: 12%;
}

.issued {
    background-color: #33e68c;
    border: 1px solid #48da9d;
}

.image-container {
    max-width: 100%;
    /*background-color: black;*/
    /*height: auto;*/
    /*margin-top: 10px;*/
    /*margin-left: 12px;*/
    /*border: 1px red solid;*/
    /*color: #fff;*/
    font-size: 15px;
    /*text-transform: capitalize;*/
}

.image-container .doc {
    height: 40px;
    width: 40px;
    float: left;
    margin-right: 15px;
    font-size: 30px;
    background: #777777;
    color: white;
    border-radius: 50%;
    padding: 10px;
}

.reason {
    background: #44ae9c;
    padding: 5px 10px;
    color: whitesmoke;
    border-radius: 20px;
}

.revenue-print {
    display: none;
}

#patient-radiology-report  th p , #patient-radiology-report  td p{
    line-height: 2;
}

@page {
    /* size: auto; */
     margin: 0.5cm 0.5cm  0.5cm  0.5cm;
}

#box-wrap {
      
    /*word-break: break-all; */

    overflow-wrap: break-word;

}

@media print {
    /** {*/
    /*    font-family: Open Sans, 'WebFont-Open Sans', serif !important;*/
    /*}*/
    /*html,
    body {
        width: 210mm;
        height: 297mm;
    }*/

    @page {
        size: A4;
        margin-top: 20px;
        margin-bottom: 35px;
    }

    .page-footer-space{
        height: 100px;
    }

    .page-header-space{
        height: 10px;
    }

    p {
        margin-bottom: 10px;
    }

    p, h6 {
        font-size: 14px;
    }

    .revenue-print {
        display: block;
    }
  
    #prescription-print table th, #prescription-print table td {
        font-size: 14px;
    }
   
    #patient-radiology-header-report  th p , #patient-radiology-header-report  td p{
        font-size: 16px;
        line-height: 1.5;
    }


    #patient-radiology-report  th p , #patient-radiology-report  td p{
        font-size: 16px;
        line-height: 2.5;
    }


    table.l_table {
        display: none;
    }

    #prescription-print {
        background: white;
    }

    p,
    h6 {
        font-size: 14px;
    }

    #prescription-print p, #treatment-print p {
        color: #212529;
        font-size: 14px;
        margin: 0;
    }

    #prescription-print h6, #treatment-print h6 {
        color: #212529;
        font-size: 20px;
    }

    #treatment-print table td {
        padding: 8px;
    }

    #general-ledger-table thead td, #general-ledger-table thead td {
        background-color: #5e5e5e;
        color: rgb(255, 255, 255);
    }

    #patient-lab-report, #patient-radiology-header-report {
        border-bottom: 3px solid #3e3e3e;
    }

    #parameter-row {
        border: 1px solid #606060;
    }

    /*#doctor-stamp {
        border-bottom: 1px solid #3e3e3e;
    }*/

    #title-background {
        background-color: rgb(242, 242, 242);
    }

    #title-background h6 {
        color: #0b0b0b;
    }

    #box-wrap {
        overflow-wrap: break-word;       
    }

    /* .footer-table {
        position: fixed;
        bottom: 0;
    } */

    table.footer-table {
        margin: 0;
        width: 205mm;
     
    }

    .p-general-report {
       /* display: flex;
        align-items: center;
        justify-content: center;
       width: 200mm; 
       */
       margin-left: 100px;
       /*margin-right: 20px;
       padding-left: 20px;
       padding-right: 20px;*/
        width : 205mm;
    }

    .inner-lab-report {
        width : 205mm;
        margin-left: 100px;
    }

    .emergency-details, .requisition-receipt{
        margin-left: 40px;
        margin-right: 50px;
    }

    .print-receipt {       
        margin-top: 40px;
        margin-left: 100px;
    }

    .surgery-report p {
        font-size: 13pt;

 
    }

    .surgery-report {
        margin-left: 100px;
        
        margin-right: 20px;
        /*padding-left: 20px;
        padding-right: 20px;
        width : 205mm;*/
    }
}

.requisition-receipt {
    padding: 30px;
}


.react-datepicker__time-container,
.react-datepicker__time,
.react-datepicker__time-box,
ul.react-datepicker__time-list {
    padding-left: unset;
    padding-right: unset;
    width: 100px;
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    width: 314px;
}

.user-name {
    color: #868686;
    text-shadow: 2px 3px 4px #dbdbdb;
}

.user-name:hover {
    color: lightgray;
    text-shadow: 1px 1px lightgray;
}

.details span {
    margin: 5px;
}

.upload_page {
    margin-left: 25px;
}

.upload_page ul {
    list-style: none;
    padding: 10px;
}

.upload_page ul li {
    padding: 8px;
}

.upload_page ul li:before {
    content: "✓";
    color: #1f8d6b;
    margin-right: 10px;
}

.sms-image img {
    width: 80%;
    margin-top: 100px;
    margin-left: 80px;
}

.drop-zone {
    max-height: 80%;
    /*width: 200px;*/
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    /*border-radius: 50%;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    flex-direction: column;
    font-size: 16px;
    padding: 10px;
}

.dark-drop-zone {
    background-color: #424242;
    border: 2px dashed rgba(255, 255, 255, 0.12);
}

ul.unstyled {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-bottom: 1px solid #eee;
    /*background-image: linear-gradient(to bottom right, #f0582a, #eb0685);*/
}

nav li {
    float: left;
}

nav li .nav-item-link {
    display: block;
    color: #757575;
    text-align: center;
    padding: 10px 16px;
    text-decoration: none;
    background-color: transparent;
    border-bottom: transparent;
    font-size: 16px;
}

nav li .nav-item-link:hover,
nav li .nav-item-link:focus {
    color: #292929;
    border-bottom: 2px solid #232323 !important;
    /*color: white;*/
    /*text-decoration: underline;*/
}

nav li .nav-item-link .active {
    border-bottom: 2px solid #202020 !important;
    background-color: transparent;
    color: #757575;
}

.border-health-info.health-info {
    border-bottom: 2px solid #656565;
}

.profile-span {
    color: #a5a5a5;
}

.health-info .info {
    border-bottom: 1px solid #656565;
}

.info h5 {
    font-size: 15px;
}

.info h6 {
    font-size: 15px;
}

.visits-info p,
.health-info p {
    margin: 0;
}

.health-heading {
    border-top: 2px solid #656565;
}

.visits-info {
    border-bottom: 1px solid #656565;
    margin-bottom: 10px;
}

.visits-info h5 {
    font-size: 16px;
}

.actions-taken {
    border-top: 3px solid darkgrey;
    border-bottom: 3px solid darkgrey;
}

.doctor-vitals {
    border-bottom: 1px solid lightgray;
}

.dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 20%;
    top: 10%;
}

.image {
    width: 600px;
}

.scan-image {
    width: 150%;
    height: 150%;
}

.scan-images {
    /*width: 100%;*/
    height: 20%;
}

.sms-image img {
    width: 100%;
    margin-top: 100px;
    margin-left: 80px;
}

.dropzone {
    max-height: 80%;
    /*width: 200px;*/
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    /*border-radius: 50%;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    flex-direction: column;
    font-size: 16px;
    padding: 10px;
}

.image-container {
    max-width: 100%;
    /*background-color: black;*/
    /*height: auto;*/
    /*margin-top: 10px;*/
    /*margin-left: 12px;*/
    /*border: 1px red solid;*/
    /*color: #fff;*/
    font-size: 15px;
    /*text-transform: capitalize;*/
}

.image-container .doc {
    height: 40px;
    width: 40px;
    float: left;
    margin-right: 15px;
    font-size: 30px;
    background: #777777;
    color: white;
    border-radius: 50%;
    padding: 10px;
}

.reason {
    background: #44ae9c;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: whitesmoke;
    border-radius: 20px;
}

/*Billing*/
.billing-card-box {
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.axis path,
.axis line {
    fill: none;
    stroke: #c4c4c4;
    shape-rendering: crispEdges;
}

.line {
    stroke: #21825c;
    stroke-width: 0.3px;
    fill: none;
}

.lineDots {
    fill: #21825c;
}

.billing-card-text {
    font-size: 1.22em;
    text-shadow: 1px 6px 4px rgba(174, 174, 174, 0.36);
    color: #727272;
    margin-right: 15px;
    margin-top: -1.5px;
}

.billing-card-icon {
    margin-right: 8px;
}

.billing-card-icon-percentage-up {
    margin-right: 4px;
    color: #42aa98;
}

.billing-card-icon-percentage-down {
    margin-right: 4px;
    color: rgba(177, 68, 99, 0.83);
}

.drug-form {
    margin-left: 35%;
}

.slide-enter {
    transform: translateY(-100%);
    transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-active {
    transform: translateY(0%);
}

.slide-leave {
    transform: translateY(0%);
    transition: 0.3s ease-in-out;
}

.slide-leave-active {
    transform: translateY(-100%);
}

.borderless-inputs {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    box-shadow: none;
}

*:focus {
    outline: none !important;
}

.sub-header {
    position: relative;
    /*background-color: white;*/
    border-bottom: 1px solid #dcdcdc;
    padding: 15px 20px 8px;
    margin-bottom: 15px;
    overflow: hidden;
}

.dark-theme-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.header-icon {
    color: #027a5f;
    font-size: 40px;
    float: left;
    margin-right: 20px;
    /*margin-top: -8px;*/
}

.header-icon-margin-right-zero {
    color: #027a5f;
    font-size: 40px;
    float: left;
    padding-left: 0px;
}

.sub-header span {
    display: inline-block;
    font-size: 13px;
}

.revenue-container .sub-header {
    margin-bottom: 0;
}

.revenue-generated-header {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
}

.sub-header h5 {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 9px;
}

.patient-section,
.visit-section {
    max-width: 980px;
    margin: 10px auto;
    width: 90%;
}

.lab-settings {
    max-width: 900px;
    margin: 10px auto;
    width: 90%;
}

.patient-details {
    /*width: 1100px;*/
    margin: 20px;
}

.new-appointment {
    max-width: 800px;
    margin: 10px auto;
}

.prescription-list {
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
}

.drug-issue {
    max-width: 600px;
    width: 95%;
    margin: 0 auto;
}

.add-scan-results {
    max-width: 800px;
    width: 95%;
    margin: 0 auto;
}

.product-trends {
    max-width: 1000px;
    width: 95%;
    margin: 0 auto;
}

.mui-tables, .fill-prescription-details {
    /*max-width: 1300px;*/
    /*width: 98%;*/
    /*margin: 0 10px;*/
}

.prev-visits {
    /*max-width: 1050px;*/
    /*width: 98%;*/
    margin: 0 3%;
}

.parameter-list {
    max-width: 800px;
    width: 98%;
    margin: 0 auto;
}

.radiology-report {
    max-width: 900px;
    width: 98%;
    margin: 0 auto;
}

.billing-details {
    max-width: 1000px;
    width: 98%;
    margin: 0 auto;
}

.lab-legend {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 18px;
}

.dark-lab-legend {
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.style-employee-details {
    max-width: 1000px;
    width: 90%;
    margin: 0 auto;
}

.styled-employee-card .employee-header {
    background: url("./images/header.jpg");
    background-size: cover;
    height: 150px;
}

.styled-employee-card {
    position: relative;
    display: flex;
    flex-direction: column;
}

.styled-employee-card .employee-image {
    position: relative;
    /*top: -130px;*/
    margin-bottom: 2rem;
    /*left: 30%;*/
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.employee-flex {
    display: flex;
    height: auto;
}

.emp-more-info-card {
    background-color: #d3d3d3;
    border-radius: 0.5rem;
    padding: 0.7rem;
}

.dark-emp-more-info-card {
    background-color: #424242;
}

.emp-other-info-row {
    margin-top: 1rem;
}

.emp-profile-info p,
.emp-more-info-card p,
.patient-prof-other-card p {
    margin: 0;
}

.patient-prof-other-card {
    padding: 0.7rem;
}

.product-details p {
    margin: 0 0 2px 0;
}

.styled-employee-card .employee-image img {
    width: 110px;
    height: 110px;
    max-width: 300px;
    max-height: 300px;
    border-radius: 50%;
    border: 3px solid #027a5f;
}

.styled-person-table {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.styled-employee-table {
    margin-left: 2.5rem;
}

.emp-edit-btn.btn-sm {
    padding: 0 0.9rem;
    border-radius: 0.8rem;
}

.emp-other-details {
    margin-top: 0.8rem;
    border-top: 1px solid #d1d1d1;
}

.dark-emp-other-details {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}

/*.case-study-table{*/
/*  max-width:1100px;*/
/*  width:98%;*/
/*  margin:0 auto;*/
/*}*/
.mui-tables .col-md-8 .col-md-4 {
    padding: 0;
}
.mui-tables{
    padding-right: 12px;
    padding-left: 12px;
}

.mui-tables .col-md-4 {
    padding: 0;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #1290a4;
    border-color: #1290a4;
    color: #fff;
}

.pagination > li > a {
    border: 1px solid rgba(118, 118, 118, 0.3);
    padding: 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #1290a4;
    border-color: #1290a4;
    outline: none;
}

.pagination > li > a,
.pagination > li > span {
    color: rgba(0, 0, 0, 0.66);
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
}

.page-item.active .page-link {
    background-color: #1290a4;
    border-color: #1290a4;
}

.page-item:hover,
.page-link:hover {
    color: #1290a4;
}

.patient-address {
    border-bottom: 1px solid #6d6d6d;
    /*overflow: hidden;*/
    display: flex;
}

.patient-address p {
    margin: 5px !important;
}

.patient-title {
    flex: 0 0 50%;
    max-width: 50%;
}

.patient-summary {
    flex: 0 0 50%;
    max-width: 50%;
}

.add-ward-buttons {
    margin-left: -20px;
}

/*.radiology-findings{*/
/*  text-align: center;*/
/*}*/
.radiology-findings h6 {
    text-decoration: underline;
}

.radiology-findings ul li {
    list-style-type: none;
}

.dashboard-layout {
    /*max-width: 1400px;*/
    /*width: 93%;*/
    margin: 0 15px;
    /*overflow: hidden;*/
}

.clear {
    display: block;
    clear: both;
}

/*.dashboard-layout::-webkit-scrollbar {*/
/*    width: 2px !important;*/
/*    height: 4px ;*/
/*}*/

.dashboard-container {
    width: 1050px;
    overflow: auto;
}

.dashboard-lg-container {
    width: 1200px;
    overflow: auto;
}

.dashboard-container::-webkit-scrollbar {
    width: 3px;
    height: 4px;
}

.dashboard-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eeeeee;
    border-radius: 10px;
}

.dashboard-container::-webkit-scrollbar-thumb {
    background-color: #e6e6e6;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.dashboard-thumb-cards {
    display: inline-flex;
    flex-wrap: nowrap;
    /*overflow: auto;*/
}

.dashboard-section {
    flex: 0 0 8%;
    max-width: 8%;
    padding-right: 10px;
    padding-left: 10px;
}

.dash-card {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    /*cursor: pointer;*/
}

table .strike-table {
    border-collapse: collapse;
}

.strike-table td {
    position: relative;
    padding: 5px 10px;
}

.strike-table .strike-out:before {
    content: " ";
    position: absolute;
    top: 48%;
    left: 0;
    border-bottom: 1px solid #d74949;
    width: 100%;
}


.styled-dashboard-card {
    position: relative;
    display: flex;
    min-width: 0;

    /*border-radius: 28px;*/
    /*background-color: blue;*/
    /*background-clip: border-box;*/
    /*border: 1px solid rgba(0,0,0,.125);*/
}

.styled-card-image {
    flex: 1 1 0;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    padding: 15px 0;
}

.styled-card-text {
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    flex: 2 1 0;
    color: #fff;
    padding: 15px 0;
}

.styled-info-text,
.styled-number-text {
    font-size: 14px;
    display: inline-block;
    /*margin-bottom: 6px;*/
    margin: 0 0 6px 0;
}

.styled-number-text {
    margin-left: 8px;
}

.styled-date {
    font-size: 18px;
    margin: 15px 12px 0 15px;
    display: inline-block;
}

.styled-blue-card-image {
    background-color: #2a7aaf;
}

.styled-blue-card-text {
    background-color: #3498db;
}

.styled-green-card-image {
    background-color: #15977d;
}

.styled-green-card-text {
    background-color: #1abc9c;
}

.progress {
    height: 3px;
    background-color: #cccfd2ed;
}

.progress-bar {
    background-color: #f9fafc;
}

.styled-icon {
    color: #fff;
    font-size: 50px;
    margin: 12px 20px 0 20px;
}

.chart-card {
    min-width: 0;
    /*border-radius: 20px;*/
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.add-bill-card {
    max-width: 1200px;
    width: 98%;
    margin: 10px auto;
}

.add-doctor-actions {
    max-width: 1000px;
    width: 90%;
    margin: 10px auto;
}


.white-td {
    color: white;
}

.black-td {
    color: black;
}

.billing-personal-information {
    display: flex;
    padding: 0 10px;
}

.payment-type-information,
.client-billing-information {
    flex: 1 1 50%;
}

.billing-personal-information p {
    margin: 0 !important;
    border-bottom: 1px solid #797979;
}

.personal-billing-heading {
    border-bottom: 1px solid #797979;
    padding: 5px 0;
    margin-bottom: 20px;
}

.payment-type-details {
    width: 90%;
}

.visit-heading {
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 20px;
    font-size: 15px;
    text-align: center;
}

.patient-bill-details p {
    margin: 5px 0;
}

.dark-visit-heading {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

/* inpatient_treatment */

.table-card {
    /*max-width: 1200px;*/
    /*width: 80%;*/
    margin: 0 30px;

}

.table-table {
    border-collapse: collapse;
    max-width: 1200px;
    width: 70%;
    margin: auto;
}

.table-table.td.th {
    border-style: hidden;
    padding: 15px;
    text-align: left;
}
.form__text_input{
    font-size: 14px;
    border-radius: 0 0.7rem 0.7rem 0;
}

.form__control,input.PhoneInputInput {
    height: calc(1.6em + .75rem + 5px);
    border-radius: .7rem;
}

input.PhoneInputInput{
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    padding: .375rem .75rem;
}

.form-control-dark {
    color: #ffffff;
    background-color: #424242;
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.form-control-dark:disabled {
    color: #ffffff;
    background-color: #2f2f2f;
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.form-control-dark:focus,
.dark-form-select .css-1pahdxg-control {
    color: #ffffff;
    background-color: #424242;
    /*border-color: #ffffff;*/
    /*box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);*/
}

.form__select .css-yk16xz-control {
    border-radius: 0.7rem !important;
}

.dark-form-select .css-yk16xz-control {
    background-color: #424242;
}

.dark-form-select .css-1wa3eu0-placeholder {
    color: #fff;
}

.form__control.presc {
    border-radius: 0.2rem;
}

.form__label, .form__control, .form_check, input.PhoneInputInput {
    font-size: 14px;

}

.form__label {
    /*padding-left: 0;*/
    margin-top: 0.5rem !important;
}

.form__control__billing {
    height: calc(1.2em + 0.55rem + 2px);
}

.sms-grey-btn {
    background-color: #d7d3d3;
    color: #4a4a4a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid #c7c6c6;
}

.sms-grey-btn:hover {
    border: 1px solid #dcdcdc;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #1d1d1d;
}

.sms-gray-btn, .sms-back-btn{
    background-color: #eee;
    color: #4a4a4a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid #DCDCDC;
}

.sms-gray-btn:hover {
    border: 1px solid #eee;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #1d1d1d;
}
.sms-back-btn{
    border-radius: 50px;
}

.sms-btn-secondary {
    background-color: #f5f5f5;
    color: #212529;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid #ddd;
}

.sms-btn-secondary:hover {
    border: 1px solid #DCDCDC;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #1d1d1d;
}

.revenue-generated-form form {
    margin-bottom: -4%;
}

.form-check-inline {
    margin-top: 0.6rem;
}

.referral-text {
    margin-bottom: 0 !important;
    cursor: pointer;
    font-size: 15px;
}

.non-active-text {
    color: #a5a5a5;
}

.active-text {
    color: #3e3e3e;
}

.dark-active-text {
    color: #f5f5f5;
}


.total__report__card {
    margin-left: 30px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.total__report {
    border-bottom: 3px solid #3e3e3e;
    margin-bottom: 0.9rem;
}

.dark-theme-report-border {
    border-bottom: 3px solid #d5d5d5;
}

.total__report p {
    margin: 0 0 0.9rem 0;
    font-size: 15px;
}

.quick__links {
    /*margin-top: -10%;*/
    margin-left: 90%;
}

.quick__links1 {
    margin-left: 75%;
}

.dashboard-link {
    margin-left: 15%;
}

.links__btn {
    padding: 8px 10px;
    border: 0;
    border-radius: 50%;
    background-color: white;
    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
    margin-left: 58%;
    margin-top: 8px;
}

.links__icon {
    font-size: 23px;
    color: #08819c;
}

.metrics__card {
    cursor: pointer;
    /*width: 75%;*/
}

.metric__item {
    width: 200px !important;
}

.metric__value {
    font-size: 22px;
    margin-top: 0;
}

/* Dashboard css */
.card_text_heading {
    font-weight: 600;
    font-size: 12px;
    color: #818ea3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin:2px;
    text-align: right;
    border-bottom: 1px solid #dcdcdc;
}

.card_text_values {
    text-align: right;
    font-size: 20px;
    margin: 0 0 6px 0;
    font-weight: 500;
}

.metric__text {
    font-size: 14px;
    margin-top: -5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.metric__value,
.metric__text {
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
}

.caret__up {
    color: #1abc9c;
}

.caret__down {
    color: #b04963;
}

.active-referral-text {
    border-bottom: 2px solid #3e3e3e;
}

.dark-referral-text {
    border-bottom: 2px solid #eeeeee;
}

.referral-border {
    border-bottom: 1px solid #656565;
}

.borderless-form-control {
    border-top: none;
    border-radius: 0;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #3e3e3e;
    height: calc(1em + 0.7rem + 2px);
    outline: none !important;
    font-size: 14px;
}

.dark-borderless-form-control {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.borderless-form-control.prescription-borderless {
    height: calc(1em + 1rem + 2px) !important;
    margin-bottom: 10px;
    width: 80%;
}

.prescribe-doctor {
    margin-left: 6rem !important;
}


.doctor-stamp-left {
    border-bottom: 1px solid #3e3e3e;
    width: 65%;
}


.doctor-stamp-right {
    border-bottom: 1px solid #3e3e3e;
    width : 65%;

}


/*
.doctor-stamp {
    border-bottom: 1px solid #3e3e3e;
    width: 70%;
}*/

/*
.doctor-stamp.doctor-stamp {
    width: 65%;
}
*/

.dark-doctor-stamp {
    border-bottom: 1px solid #fff;
}

.col-form-label {
    font-size: 14px;
}

.referral-paragraph {
    margin: 1rem 0;
    padding-left: 15px;
}

.referral-form-group {
    margin-bottom: 0 !important;
}

.referral-bold-paragraph {
    /*font-weight: 600;*/
    /*font-size: 15px;*/
    font-style: italic;
}

.billing-receipt p {
    margin: 0;
    /*font-size: 1rem;*/
}

.bill-patient-details,
.doc-report {
    border-bottom: 3px solid #3e3e3e;
}

.dark-bill-patient-details,
.dark-doc-report {
    border-bottom: 3px solid #fff;
}


.bill-receipt-table td, .bill-receipt-table th {
    border: 1px solid #717579 ;
}

.table-sm.observation-table td, .table-sm.observation-table th {
    padding: .05rem;
}

.borderless-td {
    border-bottom: 0 !important;
}

.table-form-control {
    width: 80%;
}

.product-image img {
    margin-top: -10px;
    margin-left: -20px;
    width: 200px;
}

.add-service-card {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
}

.change-password-card {
    max-width: 400px;
    width: 90%;
    margin: 0 auto;
}

.form-group-div {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.form-icon {
    position: absolute;
    right: 0;
    /*top: 1;*/
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
}

.add-medicine-card {
    max-width: 1000px;
    width: 90%;
    margin: 0 auto;
}

/*.add-medicine-card .MuiCard-root{*/
/*   overflow: hidden;*/
/*}*/

.add-role-card {
    max-width: 1500px;
    width: 90%;
    margin: 0 auto;
}

.assign_role_card {
    max-width: 600px;
    width: 90%;
    margin: auto;
}

.doctor-actions-buttons {
    margin: 0 20px;
}

.doctor-actions-buttons .btn {
    border-radius: 0;
    border-left: 1px solid #888;
    border-bottom: 1px solid #888;
}

.doctor-actions-buttons .btn:first-child {
    border-top-left-radius: 0.25rem;
}

.doctor-actions-buttons .btn:last-child {
    border-top-right-radius: 0.25rem;
    border-right: 1px solid #888;
}

.sms-btn-faded {
    background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#4682b4),
            to(#b2d0ee)
    );
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0;
    margin: 0 10px;
}

.sms-btn-faded:hover {
    color: #ffffff;
}

.sms-line-btn {
    border: 1px solid #b8b5b5;
}

.doctor-dashboard {
    margin: 0 10px;
}

.doctor-dashboard-cards {
    padding: 25px 15px;
}

.doctor-dashboard-cards .link {
    text-decoration: none;
}

.doctor-dashboard-cards .dashboard-card {
    max-width: 200px;
}

.doctor-dashboard-cards .icon {
    margin-top: 13px;
    font-size: 40px;
    color: #5f5f5f;
}

.doctor-dashboard-cards .text {
    margin-top: 0;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dark-theme-color {
    color: #ffffff;
    font-weight: 400;
}

.col-md-2.dashboard-col {
    padding-right: 8px;
}

.line-button{
    margin-right: 20px;
}

@media (min-width: 768px) {
    .dashboard-section {
        flex: 0 0 8%;
        max-width: 8%;
        padding-right: 10px;
        padding-left: 10px;
    }

    /*.col-md-2.dashboard-col {*/
    /*    flex: 0 0 14.2%;*/
    /*    max-width: 14.2%;*/
    /*}*/
}

@media (max-width: 450px) {
    .card-item {
        width: 120px;
        margin-left: auto;
        margin-right: auto;
    }

    .responsive-logo {
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
    }

    .navlink .navTab {
        font-size: 12px;
        margin-right: -24px;
        color: #242424;
    }

    .card-item .responsive-dashboard-text {
        font-size: 0.7em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .responsive-menu-text {
        font-size: 0.8em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .sms-user-icon {
        margin-right: 30px;
        margin-left: -15px;
        white-space: nowrap;
        cursor: pointer;
    }

    .doctor-dashboard-cards .dashboard-card {
        max-width: 310px;
    }
}

@media (max-width: 500px) {
    .card-item {
        width: 150px;
        margin-left: auto;
        margin-right: auto;
    }

    .sms-user-icon {
        margin-right: 30px;
        margin-left: -15px;
        white-space: nowrap;
        cursor: pointer;
    }
}

@media (min-width: 576px) {
    .visit-sm-card .col-sm-3 {
        flex: 0 0 16%;
        max-width: 16%;
    }
}

@media (max-width: 700px) {
    .card-item {
        max-width: 150px;
    }

    .user-icon {
        font-size: 14px;
        width: 30px;
        height: 30px;
        margin-top: 10px;
        position: relative;
    }

    .user-icon span {
        color: white;
        position: absolute;
        margin-left: 9px;
        margin-top: 5px;
    }

    .user-icon span.person-icon {
        color: white;
        position: absolute;
        margin-left: 9px;
        margin-top: 5px;
    }

    .responsive-patient-dashboard-text {
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        margin-left: -48px;
        text-overflow: ellipsis;
    }
}

@media (max-width: 768px) {
    div.responsive-side-div {
        display: none;
        margin-left: 100px;
    }

    .login-text {
        display: none;
    }

    .create-appointment-button {
        display: inline;
        margin-left: 10%;
    }

    .sms-text {
        display: block;
        font-size: 18px;
    }



    .responsive-login {
        margin-top: -15px;
    }

    .responsive-page {
        margin-bottom: 50px;
    }

    .responsive-logo {
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
    }

    .navlink .navTab {
        font-size: 13px;
        margin-right: -15px;
        color: #242424;
    }

    .sms-user-icon {
        margin-right: 30px;
        margin-left: -15px;
        white-space: nowrap;
        cursor: pointer;
    }

    .navigation-links {
        margin-left: 25px;
        margin-top: 2px;
    }

    .responsive-sub-logo {
        display: none;
    }

    /*Patient Dashboard*/
    .patient-card-item {
        /*padding-top: -10px;*/
        margin-bottom: -30px;
    }

    .patient-dashboard-row {
        padding-top: 8%;
        padding-bottom: 8%;
        padding-left: 30px;
    }

    .responsive-patient-dashboard-text {
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        margin-left: -50px;
    }

    .doctor-dashboard-cards {
        padding: 15px;
    }
}

@media (max-width: 968px) {
    .user-icon {
        font-size: 18px;
        width: 40px;
        height: 40px;
        position: relative;
    }

    .create-appointment-button {
        display: inline;
        margin-left: 30%;
    }

    span.responsive-tip {
        font-size: 0.8em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .user-icon span {
        color: white;
        position: absolute;
        margin-left: 12px;
        margin-top: 10px;
    }

    .user-icon span.person-icon {
        color: white;
        position: absolute;
        margin-left: 12px;
        margin-top: 10px;
    }

    .card-item .responsive-patient-dashboard-text {
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        margin-left: -50px;
        text-overflow: ellipsis;
    }

    footer {
        margin-top: -20px;
    }

    /*.user-icon .patient-row-icon .icon-row{*/
    /*  !*margin-top:-10px;*!*/
    /*  !*padding-top: -1.5em;*!*/
    /*}*/
    .patient-dashboard-row {
        margin-top: -3px;
    }

    .sms-user-icon {
        margin-right: 30px;
        margin-left: -15px;
        white-space: nowrap;
        cursor: pointer;
    }
}

@media (max-width: 1024px) {
    .doctor-dashboard-cards {
        padding: 15px;
    }
}

@media (max-width: 1109px) {
    .card-item .responsive-dashboard-text {
        font-size: 0.95em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .card-item {
        max-width: 180px;
    }
}

@media (min-width: 1290px) {
    .doctor-dashboard-cards {
        padding: 15px;
    }

    .doctor-dashboard-cards .dashboard-card {
        max-width: 310px;
    }
}

.center-button {
    align-self: flex-end;
    margin-bottom: 6px;
}

/*CSS for the month year filter */
.react-month-picker {
    margin-right: 10px;
}

.react-month-picker .col_mp {
    margin: 1px;
}

.react-month-picker .span_1_of_3_mp {
    width: 32%;
}

.react-month-picker .month-input {
    margin-right: 10px;
    border-radius: 0.7rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

.tablerightborder {
    border-right: 1px solid #909090;
}

.tablerowspace {
    border-collapse:separate;
    border-spacing:0 40px;
}

.react-month-picker .calendar-container {
    max-width: 360px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background: #fff;
    border: 2px solid #eee;
    border-radius: 0.7rem;
    padding: 2px;
}

.react-month-picker .selected_cell {
    background-color: #027a5f;
    font-style: italic;
    color: #fff;
}

.react-month-picker .selected_date_mp {
    font-size: 15px;
    color: #232323;
    font-weight: bold;
}

.react-month-picker .col_mp:hover {
    color: white;
    background-color: #027a5f;
    cursor: pointer;
}
/*
Added for the Ante Natal Health
*/
hr.rounded {
    border-top: 2px solid #1fa7b0;
    border-radius: 2px;
}

@media screen and (max-width: 500px) {
    .table-antenatal {
        width: 20vw;
    }
}

@media screen and (max-width: 900px) {
    .table-antenatal {
        width: 450px;
    }
}

@media screen and (min-width: 900px) {
    .table-antenatal {
        width: 75vw;
    }
}

.antenatal-form {
    font-weight: 600;
}

.antenatal-form-h5 {
    font-size: 15px;
    font-weight: 600;
}

.align-cols {
    align-self: flex-end;
}

.align-cols-center {
    align-self: center;
}

.text-done {
    color: #2a7aaf;
}

.vertical-align-table-text {
    transform: rotate(-180deg);
    writing-mode: vertical-lr;
}

.ante-table-width {
    width: 140px;
}


.dashboard-title-style {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #0F0F0F;
}


/* DASHBOARD STYLES */
.dashboard-subtitle-style {
    font-weight: 400;
    font-size: 18px;
    color: #7E7E7E;
}

.dashboard-card-font-color {
    font-family: 'Visby CF';
    font-style: normal;
    font-weight: 700;
    color: #355AA8;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin:2px;
}

.dashboard-card-font-color-subtitle {
    font-family: 'Visby CF';
    font-style: normal;
    font-weight: 700;
    color: #355AA8;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin:5px;
}

.orange-short-border {
    width: 34px;
    border-bottom: 2px solid #F76B1D;
    margin-left:2px;
}

.card-value-text {
    font-weight: 600;
    font-size: 12px;
    color: #1A1A1A;
    margin: 5px;
}

.card-small-text {
    font-weight: 400;
    font-size: 11px;
    color: #939393;
    margin:5px;
}

.no-border-dropdown {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #355AA8;
    border: none; 
    outline: none;
    width: fit-content;
}

.dashboard-dropdown {
    border-radius: 15px;
    background: #DCF9FF;
    margin: auto;
    font-size: 10px;
}

.dropdown-filter {
    font-family: 'Inter';
    font-style: normal;
    color: #355AA8;
    border: none; 
    outline: none;
    width: max-content;
    border-radius: 10px;
    background: #DCF9FF;
    margin: auto;
    text-align: center;
    padding: 6px;
    font-weight: 500;
    font-size: 10px;
}

.dropdown-style-dashboard[aria-labelledby = ddown] {
    background-color: lightblue;
   
    border-radius: 0;
    margin: 0;
}

.sms-dashboard-btn {
    background-color: #389081;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 16px;
    border: 2px solid #389081;
    font-size: 10px;
    padding: 4px;
    box-shadow: none;
}


.doctors_side_bar{
    padding-right: 6px; 
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    /* width */

}
.doctors_side_bar::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
.doctors_side_bar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey; 
        border-radius: 15px;
    }

    /* Handle */
.doctors_side_bar::-webkit-scrollbar-thumb {
        background: #F76B1D; 
        border-radius: 10px;
    }

    /* Handle on hover */
.doctors_side_bar::-webkit-scrollbar-thumb:hover {
        background: #009eb3; 
    }
